.faqs{
    text-align: center;
    color: #FF7D90;
    font-family: 'Barlow-700';
    font-size: 15px;
    margin-top: 50px;
    margin-bottom: 50px;
}
.faqs h3{
    color: #1C2347;
    margin-top: 15px;
    font-size: 32px;
}
.faq-blocks {
    background: #FFFFFF; 
    box-shadow: 0px 0px 34px rgba(0, 0, 0, 0.12); 
    margin-bottom: 15px;
}
.faq-blocks > div:first-child {
    border-left:5px solid #FF7D90;
    cursor: pointer;
    margin-bottom: 0; 
    padding: 20px;
}
.faq-blocks > div > i {
    color:#FF7D90;
}
.faq-question {
    font-family: 'barlow-800';
    margin-bottom: 0px;
}
.faq-question span{
    margin-left: 10px;
    color: #1C2347;
    font-family: 'barlow-600';
}
.faq-answers {
    background: #E2E2E2;
    padding: 10px 25px;
    font-family: 'rubik-400';
}
