#banner-form {
    background-image: url(./banner-form.png);
    background-size: 100% 100%;
    padding: 20px 0 40px 0;
}
#banner{padding: 20px 20px 70px 20px;}
.banner-header {
    display: grid; 
    grid-template-columns: repeat(2, auto);
    justify-content: space-between; 
    align-items: center; 
    margin-bottom: 50px;
}
.banner-header > div:first-child img {
    width: 180px;
}
.banner-header > div:last-child {
    display: grid;
    grid-template-columns: repeat(2, auto);
    align-items: center;
}
.banner-header p {
    margin: 0 20px 0 0;
}
.banner-header p a {
    color: #FFF;
    font-size: 18px;
    text-decoration: none;
}
.banner-central-part {
    display: grid; 
    grid-template-columns: 1fr auto;
    justify-content: space-between;
    align-items: center;
}
.left-content h4 {
    font-size: 40px;
    font-family: 'barlow-700';
    color: #FFF;
    margin-bottom: 0;
}
.left-content > h4 > span {
    color:#FF7D90;
    display: block;
}
.left-content ul {
    font-family: 'rubik-300';
    font-size: 18px;
    padding-left: 20px;
    margin: 60px 0 40px 0;
    color: #FFF;
}
.youtube-icon span{
    color: #FFF;
    font-family: 'barlow-500';
    text-decoration: underline;
}
.right-side-image form {
    background-image: url('./form-background.png');
    background-size: 100% 100%;
    width: 500px;
    padding: 50px 65px 50px 60px;
}
.right-side-image form h3{
    color: #000000;
    font-family: 'barlow-700';
    font-size: 18px;
}
.seamless-flow {
    background-image: url(./seamless-flow.png);
    background-size: 100% 50%;
    background-repeat: no-repeat;
    text-align: center;
    padding: 100px 0;
}
.seamless-flow h2 {
    font-family: 'barlow-700';
    font-size: 35px;
    margin-bottom: 20px;
}
.seamless-flow p {
    color: #6B6B6B;
    font-size: 18px;
    font-family: 'rubik-400';
}
.seamless-flow img {
    width: 100%;
}
.why-choose-us {
    padding: 0 0 100px 0;
}
.verification-image {
    text-align: center;
}
.verification-image p{
    font-family: 'barlow-700';
    font-size: 18px;
    color: #FF7D90;
}
.verification-image h3{
    font-family: 'barlow-700';
    font-size: 35px;
}
.features {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 40px;
}
.features > div {
    padding: 15px 30px;
    font-family: 'rubik-400';
    color: #777777;
    font-size: 16px;
    border-right: 1px solid #C4C4C4DB;
}
.features > div:last-child {
    border-right: 0;
    border-bottom: 0;
}
.features > div > h2 {
    font-family: 'barlow-700';
    font-size: 20px;
    color: #1C2347;
    margin-top: 15px;
}
.features > div > p {
    margin-bottom: 0;
}
.features > div > img {
    height: 40px;
}
/* =====----- Truth, Privacy, Customized Solutions start here -----===== */
#truth-privacy-solutions {
    background-color: #1C2347;
    padding: 50px 0;
}
#truth-privacy-solutions > div > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    color: #FFF;
    align-items: center;
}
#truth-privacy-solutions > div > div > div {
    padding: 40px 0;
}
#truth-privacy-solutions > div > div > div:first-child {
    padding-right: 40px;
}
#truth-privacy-solutions > div > div > div:last-child {
    padding-left: 40px;
}
#truth-privacy-solutions > div > div > div > h4 {
    font-family: 'barlow-700';
    font-size: 35px;
    margin-bottom: 20px;
}
#truth-privacy-solutions > div > div > div > p {
    font-family: 'rubik-400';
    font-size: 18px;
}
#truth-privacy-solutions > div > div > div > img {
    width: 100%;
}
/* =====----- Truth, Privacy, Customized Solutions end here -----===== */
.step-to-confidence{
    margin-top: 50px;
}
.how-its-work{
    text-align: center;
    color: #FF7D90;
    font-family: 'barlow-700';
    font-size: 15px;
}
.how-its-work h3{
    text-align: center;
    color: #1C2347;
    font-family: 'barlow-700';
    font-size: 30px;
}
.step-to-details {
    margin-top: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.step-to-details > div:first-child > img {
    width: 100%;
}
.verification-journey-steps > div {
    padding: 15px 15px 15px 25px;
    cursor: pointer;
    display: grid;
    grid-template-columns: 70px 1fr;
    border-radius: 1.5rem;
    margin-bottom: 25px;
}
.verification-journey-steps > div img {
    width: 45px;
}
.verification-journey-steps div h3 {
    font-family: 'barlow-700';
    color: #1C2347;
    font-size: 24px;
}
.verification-journey-steps div p {
    font-family: 'rubik-400';
    color: #777;
    font-size: 16px;
    margin-bottom: 0;
}
.verification-journey-steps div.active {
    background-color: #FFE8EB94;
}
.key-benifits{
    text-align: center;
    color: #FF7D90;
    font-family: 'barlow-700';
    font-size: 15px;
}
.key-benifits span{
    font-family: 'barlow-700';
    font-size: 18px;
}
.key-benifits h3{
    text-align: center;
    color: #1C2347;
    font-family: 'barlow-700';
    font-size: 30px;
    margin-top: 5px;
    margin-bottom: 45px
}
.key-benifits-content-box{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}
.uncover-the-benifits {
    margin: 0 5px;
    background: #FFFFFF;
    border: 1px solid #777777;
    padding: 20px;
}
.uncover-the-benifits:first-child {
    margin: 0 10px 0 0;
}
.uncover-the-benifits:last-child {
    margin: 0 0 0 10px;
}
.uncover-the-benifits > h4 {
    font-family: 'barlow-700';
    font-size: 22px;
    color: #000000;
    margin: 15px 0 10px 0;
}
.uncover-the-benifits > p {
    font-family: 'rubik-400';
    margin-bottom: 0;
    color: #777;
}
.affordable-rates{
    padding: 80px 0px 0px 80px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    margin-top: 50px;
    background: linear-gradient(109.54deg, #1C2347 -5.65%, #FF7D90 86.17%);
}
.left-side-content {
    font-family: 'barlow-700';
    font-size: 36px;
    color: #FFFFFF;
}
.left-side-content p{
    font-family: 'barlow-700';
    font-size: 30px;
    color: #FFFFFF;
    margin-top: 50px;
}
.left-side-content span{
    font-family: 'barlow-400';
    font-size: 15px;
    color: #FFFFFF;
}
.right-side-content img{
    width: 100%;
}
.start-to-end-verification {
    background-image: url(./power-verification.png);
    background-size: 100% 100%;
    padding: 40px;
    margin: 50px 0;
    border-radius: 20px;
}
.sign-up-today{
    padding: 40px;
    text-align: center;
    background: rgba(255, 255, 255, 0.22);
    backdrop-filter: blur(8px);
    border-radius: 20px;
}
.sign-up-today p {
    color: #FFFFFF;
    font-size: 24px;
    font-family: 'barlow-600';
    margin-bottom: 40px;
}



@media only screen and (max-width: 600px){
    
.banner-header {
    grid-template-columns:auto;
}
.banner-central-part {
    grid-template-columns: 1fr;
}
.build-trust {
    grid-template-columns:1fr;
}
.step-to-details {
    grid-template-columns: 1fr;
}
.key-benifits-content-box{
    grid-template-columns: 1fr;
}
.affordable-rates{
    grid-template-columns: 1fr; 
    padding: 20px;
}
.step-to-left-side{
    margin-bottom: 20px;
}
.banner-header >div:first-child img{
    margin-bottom: 20px;
}
.banner-header > div:last-child {
    grid-template-columns: 1fr 1fr;
}
.uncover-the-benifits:first-child {
    margin: 0 0 0 0;
    margin-bottom: 10px;
}
.uncover-the-benifits {
    margin: 0 0;
    margin-bottom: 10px;
}
.uncover-the-benifits:last-child {
    margin: 0 0 0 0;
}
.right-side-image form {
    width: 100%;
    background: #fff;
    padding: 30px;
    margin-top: 20px;
    border-radius: 1rem;
}
.right-side-content{
    display: none;
}
.footer-heading {
    margin-top: 40px;
}
.contact-info{
    margin-bottom: 30px;
}
.unique-needs > div {
    padding: 20px 20px 20px 20px;
    color: #FFF;
}
.verification-journey-steps > div {
    padding: 20px 20px 20px 20px;
}
.step-to-details > div:first-child > img {
    padding: 20px 20px;
}
    .seamless-flow {
        background: none;
        padding: 50px 0;
    }
    .seamless-flow h2 {
        font-size: 32px;
    }
    .why-choose-us {
        padding: 0 0 50px 0;
    }
    .verification-image p {
        margin-bottom: 0;
    }
    .verification-image h3 {
        font-size: 32px;
    }
    .features {
        grid-template-columns: 1fr;
        margin-top: 0;
    }
    .features > div {
        border-right: 0;
        border-bottom: 1px solid #C4C4C4DB;
        padding: 30px 15px;
    }
    #truth-privacy-solutions > div > div {
        grid-template-columns: 1fr;
        padding: 50px 0;
    }
    #truth-privacy-solutions > div > div > div {
        padding: 0;
    }
    #truth-privacy-solutions > div > div > div:first-child {
        padding-right: 0;
    }
    #truth-privacy-solutions > div > div > div:last-child {
        padding-left: 0;
    }
    #truth-privacy-solutions > div > div > div > h4 {
        font-size: 32px;
        margin-bottom: 15px;
    }
    #truth-privacy-solutions > div > div > div > p {
        font-size: 16px;
    }
    .sign-up-today p {
        font-size: 18px;
        margin-bottom: 20px;
    }
}